/* https://gist.github.com/MoOx/9137295 */
button {
  border: 0;
  margin: 0;
  padding: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  text-align: inherit;
  overflow: visible;
  width: auto;
  background: transparent;
  line-height: normal;
  outline: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
@media (hover: hover) and (pointer: fine) {
  .solid-button:not(:disabled):hover,
  .solid-button:not(:disabled):focus {
    background: var(--highlight-color);
  }
}

.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--size) * 3.5);
  width: 100%;
  height: calc(var(--size) * 7);
  padding: 0 calc(var(--size) * 2.5);
  line-height: calc(var(--size) * 7 - 4px);
  color: var(--text-color);
  background: var(--bg-color);
  font-size: 20px;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  transition: color 0.2s ease, border-color 0.2s ease, opacity 0.2s ease;
  box-shadow: 0px 0px 2px -1px rgba(167, 166, 186, 1), 0px 0px 2px 0px rgba(167, 166, 186, 0.4),
    0px 0px 24px -4px rgba(167, 166, 186, 0.6);
}
.btn:not(:disabled).btnActive {
  color: var(--highlight-color);
  border-color: var(--highlight-color);
}
.btn:not(:disabled).btnSelected {
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
  color: rgba(var(--bg-rgb), 1);
}
.btn:disabled {
  color: var(--text-color);
  border-color: var(--text-color);
  opacity: 0.5;
}
.btn .btnIcon {
  min-width: 0;
  flex-shrink: 0;
  width: 30px;
  margin-right: -6px;
}
.btn .btnLabel {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn .btnLoader {
  position: relative;
  left: auto;
  top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  flex-shrink: 0;
  background-color: transparent;
  width: calc(var(--size) * 2.5);
  height: calc(var(--size) * 2.5);
  z-index: auto;
}
.btn > .btnLoader > .spinner {
  width: 100%;
  height: 100%;
  margin: auto;
  border: 3px solid var(--divider-color);
  border-radius: 50%;
  border-top-color: var(--text-color);
  animation: btnSpinner 1s ease infinite;
}
.btn:not(:disabled).btnActive > .btnLoader > .spinner {
  border-top-color: var(--highlight-color);
}
.btn:not(:disabled).btnSelected > .btnLoader > .spinner {
  border-top-color: rgba(var(--bg-rgb), 1);
}
@keyframes btnSpinner {
  to {
    transform: rotate(360deg);
  }
}
@media (hover: hover) and (pointer: fine) {
  .languageButton:not(:disabled):hover,
  .languageButton:not(:disabled):focus,
  .btn:not(:disabled):hover,
  .btn:not(:disabled):focus {
    color: var(--highlight-color);
    border-color: var(--highlight-color);
  }
  .btnSelected:not(:disabled):hover,
  .btnSelected:not(:disabled):focus {
    background-color: var(--highlight-color);
    border-color: var(--text-color);
    color: rgba(var(--bg-rgb), 1);
  }
  .btn:not(:disabled):hover > .btnLoader > .spinner,
  .btn:not(:disabled):focus > .btnLoader > .spinner {
    border-top-color: var(--highlight-color);
  }
  .btnSelected:not(:disabled):hover > .btnLoader > .spinner,
  .btnSelected:not(:disabled):focus > .btnLoader > .spinner {
    border-top-color: rgba(var(--bg-rgb), 1);
  }
}

/* Utils */

.btnAlignSpread {
  justify-content: space-between;
}
.btnMarginRight {
  margin-right: calc(var(--size) * 1.5);
}
.btnMarginLeft {
  margin-right: calc(var(--size) * 1.5);
}
.btnSkipGuide {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: min(calc(var(--size) * 7), 20%);
  padding: 0 25px;
}

.cta-parent {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  height: 70px;
  justify-content: center;
  background-color: var(--bg-color);
  box-shadow: 0px 0px 2px -1px rgba(167, 166, 186, 1), 0px 0px 2px 0px rgba(167, 166, 186, 0.4),
    0px 0px 24px -4px rgba(167, 166, 186, 0.6);
  z-index: 1;
}

.cta-button,
.moreArtButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  min-height: 50px;
  max-width: 250px;
  font-size: 15px;
  padding: 0 25px;
  border-radius: 25px;
  font-family: 'SatoshiMedium', sans-serif;
  box-sizing: border-box;
}

.cta-button {
  color: var(--bg-color);
  background: var(--text-color);
  border: 2px solid var(--text-color);
  margin: 10px auto 20px auto;
  height: 50px;
  max-width: 100%;
}

.moreArtButton {
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: var(--bg-color);
  background: var(--highlight-color);
  white-space: nowrap;
  max-width: 300px;
}

@media (hover: hover) and (pointer: fine) {
  .moreArtButton:not(:disabled):hover,
  .moreArtButton:not(:disabled):focus {
    color: var(--highlight-color);
    background: rgba(var(--bg-rgb), 0.9);
  }

  .cta-button:not(:disabled):hover,
  .cta-button:not(:disabled):focus {
    background: var(--highlight-color);
    border-color: var(--highlight-color);
    color: var(--bg-color);
  }
}

.svgIcon {
  fill: none;
  stroke: currentColor;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
