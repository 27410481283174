@font-face {
  font-family: 'SatoshiRegular';
  font-style: normal;
  font-display: swap;
  src: url('assets/fonts/Satoshi/Satoshi-Regular.woff') format('woff'),
    url('assets/fonts/Satoshi/Satoshi-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SatoshiMedium';
  font-display: swap;
  src: url('assets/fonts/Satoshi/Satoshi-Medium.woff') format('woff'),
    url('assets/fonts/Satoshi/Satoshi-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SatoshiBold';
  font-display: swap;
  src: url('assets/fonts/Satoshi/Satoshi-Bold.woff') format('woff'),
    url('assets/fonts/Satoshi/Satoshi-Bold.ttf') format('truetype');
}

:root {
  /* Base theme, used for non-themable components and providing default theme */
  --base-text-rgb: 0, 0, 42;
  --base-bg-rgb: 255, 255, 255;
  --base-highlight-rgb: 31, 24, 192;

  /* Themable variables */
  --text-rgb: var(--theme-text-rgb, var(--base-text-rgb));
  --bg-rgb: var(--theme-bg-rgb, var(--base-bg-rgb));
  --highlight-rgb: var(--theme-action-active-rgb, var(--base-highlight-rgb));

  /* Colors derived from themable variables */
  --text-color: rgba(var(--text-rgb), 1);
  --bg-color: rgba(var(--bg-rgb), 1);
  --bg-gradient: linear-gradient(0deg, rgba(var(--bg-rgb), 1) 0%, rgba(var(--bg-rgb), 0) 100%);
  --highlight-color: rgba(var(--highlight-rgb), 1);
  --divider-color: rgba(var(--text-rgb), 0.2);

  /* Sizing */
  --size: 8px;
  --margin: calc(var(--size) * 3);

  --quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

model-preview {
  --progress-bar-color: rgba(255, 255, 255, 0);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  background-color: rgb(var(--bg-rgb));
}
html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  color: var(--text-color);
  font-family: 'SatoshiRegular', Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.5;
  user-select: none;
  -webkit-touch-callout: none;
}

/*
iOS Safari orientation fix:
-> https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
*/
html,
body {
  height: 100vh;
  overflow: hidden;
}
body {
  height: -webkit-fill-available;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Resets */

:focus {
  outline: none;
}

/* Common elements */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 var(--margin) 0;
  color: var(--heading-color);
  font-weight: 400;
}
h1 {
  font-size: 28px;
  line-height: 1.25;
}
h2 {
  font-size: 26px;
  line-height: 1.3;
}
h3 {
  font-size: 24px;
  line-height: 1.35;
}
h4 {
  font-size: 22px;
  line-height: 1.4;
}
h5 {
  font-size: 20px;
  line-height: 1.45;
}
h6 {
  font-size: 18px;
  line-height: 1.5;
}
a {
  color: var(--text-color);
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  a:not(:disabled):hover,
  a:not(:disabled):focus {
    color: var(--highlight-color);
    text-decoration: underline;
  }
}
del {
  text-decoration: line-through;
}
em {
  font-style: italic;
}
p {
  margin: 0 0 var(--margin) 0;
}
ul,
ol {
  margin: 0 0 var(--margin) 0;
  padding: 0 0 0 var(--margin);
}
ul {
  list-style: disc outside none;
}
ol {
  list-style: decimal outside none;
}
li + li {
  margin: calc(var(--margin) / 2) 0 0 0;
}
li > ol,
li > ul {
  margin-top: var(--margin);
}
hr {
  border: 0;
  border-top: 1px solid var(--divider-color);
  margin: 0 0 var(--margin) 0;
}
blockquote {
  margin: 0;
  padding-left: calc(var(--size) * 2);
  border-left: 2px solid var(--text-color);
}

/* Checkbox field */

.checkboxField {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: calc(20px * 1.45);
  cursor: pointer;
}
.checkboxFieldIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: calc(var(--size) * 3);
  width: calc(var(--size) * 3);
  height: calc(20px * 1.45);
  margin-right: calc(var(--size) * 2);
  color: var(--text-color);
  opacity: 0.6;
  transition: opacity 0.2s ease;
}
.checkboxField:disabled > .checkboxFieldIcon {
  opacity: 0.3;
}
.checkboxField:not(:disabled).selected > .checkboxFieldIcon {
  opacity: 1;
}
@media (hover: hover) and (pointer: fine) {
  .checkboxField:not(:disabled):hover > .checkboxFieldIcon,
  .checkboxField:not(:disabled):focus > .checkboxFieldIcon {
    color: var(--highlight-color);
  }
}
.checkboxFieldIcon svg {
  flex-grow: 1;
}
.checkboxFieldLabel {
  font-size: 20px;
  line-height: 1.45;
  color: var(--text-color);
  transition: color 0.2s ease;
}
.checkboxField:disabled > .checkboxFieldLabel {
  opacity: 0.5;
}
@media (hover: hover) and (pointer: fine) {
  .checkboxField:not(:disabled):hover > .checkboxFieldLabel,
  .checkboxField:not(:disabled):focus > .checkboxFieldLabel {
    color: var(--highlight-color);
  }
}

/* Checkbox list */

.checkboxList {
  border-top: 1px solid var(--divider-color);
}
.checkboxList > * {
  border-bottom: 1px solid var(--divider-color);
  padding: calc(var(--size) * 2) calc(var(--size) * 1);
  transition: color 0.2s ease, border-color 0.2s ease;
}

/* Intro loader */

@keyframes introLoaderSpin {
  to {
    transform: rotate(360deg);
  }
}
.introLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--bg-color);
  opacity: 1;
  z-index: 99999;
  pointer-events: none;
  transition: opacity 0s ease, background-color 0.4s ease;
}
.introLoader.hidden {
  opacity: 0;
  transition: opacity 0.6s ease, background-color 0.4s ease;
}
.introLoader > .spinner {
  width: 80px;
  height: 80px;
  margin: auto;
  border: 6px solid var(--divider-color);
  border-radius: 50%;
  border-top-color: var(--text-color);
  animation: introLoaderSpin 1s ease infinite;
  animation-play-state: running;
  transition: border-color 0.4s ease;
}

/* Screen reader */
/* https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034 */

.srOnly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}
