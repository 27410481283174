.draftBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 15px;
  top: 15px;
  height: 40px;
  padding: 0 calc(var(--size) * 2);
  background-color: #e83692;
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: 'SatoshiMedium', sans-serif;
  z-index: 9999999;
}
