@font-face {
  font-family: SatoshiRegular;
  font-style: normal;
  font-display: swap;
  src: url("Satoshi-Regular.23214b96.woff") format("woff"), url("Satoshi-Regular.e855a0d3.ttf") format("truetype");
}

@font-face {
  font-family: SatoshiMedium;
  font-display: swap;
  src: url("Satoshi-Medium.de33c0c3.woff") format("woff"), url("Satoshi-Medium.0cd9b7ce.ttf") format("truetype");
}

@font-face {
  font-family: SatoshiBold;
  font-display: swap;
  src: url("Satoshi-Bold.d6123eb6.woff") format("woff"), url("Satoshi-Bold.642f978f.ttf") format("truetype");
}

:root {
  --base-text-rgb: 0, 0, 42;
  --base-bg-rgb: 255, 255, 255;
  --base-highlight-rgb: 31, 24, 192;
  --text-rgb: var(--theme-text-rgb, var(--base-text-rgb));
  --bg-rgb: var(--theme-bg-rgb, var(--base-bg-rgb));
  --highlight-rgb: var(--theme-action-active-rgb, var(--base-highlight-rgb));
  --text-color: rgba(var(--text-rgb), 1);
  --bg-color: rgba(var(--bg-rgb), 1);
  --bg-gradient: linear-gradient(0deg, rgba(var(--bg-rgb), 1) 0%, rgba(var(--bg-rgb), 0) 100%);
  --highlight-color: rgba(var(--highlight-rgb), 1);
  --divider-color: rgba(var(--text-rgb), .2);
  --size: 8px;
  --margin: calc(var(--size) * 3);
  --quad: cubic-bezier(.455, .03, .515, .955);
}

model-preview {
  --progress-bar-color: #fff0;
}

*, :before, :after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
}

html {
  background-color: rgb(var(--bg-rgb));
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

body {
  color: var(--text-color);
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  font-family: SatoshiRegular, Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.5;
}

html, body {
  height: 100vh;
  overflow: hidden;
}

body {
  height: -webkit-fill-available;
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
}

:focus {
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 var(--margin) 0;
  color: var(--heading-color);
  font-weight: 400;
}

h1 {
  font-size: 28px;
  line-height: 1.25;
}

h2 {
  font-size: 26px;
  line-height: 1.3;
}

h3 {
  font-size: 24px;
  line-height: 1.35;
}

h4 {
  font-size: 22px;
  line-height: 1.4;
}

h5 {
  font-size: 20px;
  line-height: 1.45;
}

h6 {
  font-size: 18px;
  line-height: 1.5;
}

a {
  color: var(--text-color);
  text-decoration: underline;
}

@media (hover: hover) and (pointer: fine) {
  a:not(:disabled):hover, a:not(:disabled):focus {
    color: var(--highlight-color);
    text-decoration: underline;
  }
}

del {
  text-decoration: line-through;
}

em {
  font-style: italic;
}

p {
  margin: 0 0 var(--margin) 0;
}

ul, ol {
  margin: 0 0 var(--margin) 0;
  padding: 0 0 0 var(--margin);
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li + li {
  margin: calc(var(--margin) / 2) 0 0 0;
}

li > ol, li > ul {
  margin-top: var(--margin);
}

hr {
  border: 0;
  border-top: 1px solid var(--divider-color);
  margin: 0 0 var(--margin) 0;
}

blockquote {
  padding-left: calc(var(--size) * 2);
  border-left: 2px solid var(--text-color);
  margin: 0;
}

.checkboxField {
  width: 100%;
  min-height: 29px;
  cursor: pointer;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.checkboxFieldIcon {
  min-width: calc(var(--size) * 3);
  width: calc(var(--size) * 3);
  height: 29px;
  margin-right: calc(var(--size) * 2);
  color: var(--text-color);
  opacity: .6;
  justify-content: center;
  align-items: center;
  transition: opacity .2s;
  display: flex;
}

.checkboxField:disabled > .checkboxFieldIcon {
  opacity: .3;
}

.checkboxField:not(:disabled).selected > .checkboxFieldIcon {
  opacity: 1;
}

@media (hover: hover) and (pointer: fine) {
  .checkboxField:not(:disabled):hover > .checkboxFieldIcon, .checkboxField:not(:disabled):focus > .checkboxFieldIcon {
    color: var(--highlight-color);
  }
}

.checkboxFieldIcon svg {
  flex-grow: 1;
}

.checkboxFieldLabel {
  color: var(--text-color);
  font-size: 20px;
  line-height: 1.45;
  transition: color .2s;
}

.checkboxField:disabled > .checkboxFieldLabel {
  opacity: .5;
}

@media (hover: hover) and (pointer: fine) {
  .checkboxField:not(:disabled):hover > .checkboxFieldLabel, .checkboxField:not(:disabled):focus > .checkboxFieldLabel {
    color: var(--highlight-color);
  }
}

.checkboxList {
  border-top: 1px solid var(--divider-color);
}

.checkboxList > * {
  border-bottom: 1px solid var(--divider-color);
  padding: calc(var(--size) * 2) calc(var(--size) * 1);
  transition: color .2s, border-color .2s;
}

@keyframes introLoaderSpin {
  to {
    transform: rotate(360deg);
  }
}

.introLoader {
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  opacity: 1;
  z-index: 99999;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  transition: opacity, background-color .4s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.introLoader.hidden {
  opacity: 0;
  transition: opacity .6s, background-color .4s;
}

.introLoader > .spinner {
  width: 80px;
  height: 80px;
  border: 6px solid var(--divider-color);
  border-top-color: var(--text-color);
  border-radius: 50%;
  margin: auto;
  transition: border-color .4s;
  animation: introLoaderSpin 1s infinite;
}

.srOnly {
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  width: 1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.draftBanner {
  height: 40px;
  padding: 0 calc(var(--size) * 2);
  color: #fff;
  letter-spacing: 1px;
  z-index: 9999999;
  background-color: #e83692;
  justify-content: center;
  align-items: center;
  font-family: SatoshiMedium, sans-serif;
  font-size: 14px;
  display: flex;
  position: fixed;
  top: 15px;
  left: 15px;
}

button {
  color: inherit;
  font: inherit;
  text-align: inherit;
  width: auto;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  outline: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  overflow: visible;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@media (hover: hover) and (pointer: fine) {
  .solid-button:not(:disabled):hover, .solid-button:not(:disabled):focus {
    background: var(--highlight-color);
  }
}

.btn {
  border-radius: calc(var(--size) * 3.5);
  width: 100%;
  height: calc(var(--size) * 7);
  padding: 0 calc(var(--size) * 2.5);
  line-height: calc(var(--size) * 7 - 4px);
  color: var(--text-color);
  background: var(--bg-color);
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  transition: color .2s, border-color .2s, opacity .2s;
  display: flex;
  position: relative;
  box-shadow: 0 0 2px -1px #a7a6ba, 0 0 2px #a7a6ba66, 0 0 24px -4px #a7a6ba99;
}

.btn:not(:disabled).btnActive {
  color: var(--highlight-color);
  border-color: var(--highlight-color);
}

.btn:not(:disabled).btnSelected {
  background-color: var(--highlight-color);
  border-color: var(--highlight-color);
  color: rgba(var(--bg-rgb), 1);
}

.btn:disabled {
  color: var(--text-color);
  border-color: var(--text-color);
  opacity: .5;
}

.btn .btnIcon {
  min-width: 0;
  width: 30px;
  flex-shrink: 0;
  margin-right: -6px;
}

.btn .btnLabel {
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.btn .btnLoader {
  min-width: 0;
  width: calc(var(--size) * 2.5);
  height: calc(var(--size) * 2.5);
  z-index: auto;
  background-color: #0000;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
}

.btn > .btnLoader > .spinner {
  width: 100%;
  height: 100%;
  border: 3px solid var(--divider-color);
  border-top-color: var(--text-color);
  border-radius: 50%;
  margin: auto;
  animation: btnSpinner 1s infinite;
}

.btn:not(:disabled).btnActive > .btnLoader > .spinner {
  border-top-color: var(--highlight-color);
}

.btn:not(:disabled).btnSelected > .btnLoader > .spinner {
  border-top-color: rgba(var(--bg-rgb), 1);
}

@keyframes btnSpinner {
  to {
    transform: rotate(360deg);
  }
}

@media (hover: hover) and (pointer: fine) {
  .languageButton:not(:disabled):hover, .languageButton:not(:disabled):focus, .btn:not(:disabled):hover, .btn:not(:disabled):focus {
    color: var(--highlight-color);
    border-color: var(--highlight-color);
  }

  .btnSelected:not(:disabled):hover, .btnSelected:not(:disabled):focus {
    background-color: var(--highlight-color);
    border-color: var(--text-color);
    color: rgba(var(--bg-rgb), 1);
  }

  .btn:not(:disabled):hover > .btnLoader > .spinner, .btn:not(:disabled):focus > .btnLoader > .spinner {
    border-top-color: var(--highlight-color);
  }

  .btnSelected:not(:disabled):hover > .btnLoader > .spinner, .btnSelected:not(:disabled):focus > .btnLoader > .spinner {
    border-top-color: rgba(var(--bg-rgb), 1);
  }
}

.btnAlignSpread {
  justify-content: space-between;
}

.btnMarginRight, .btnMarginLeft {
  margin-right: calc(var(--size) * 1.5);
}

.btnSkipGuide {
  width: auto;
  height: min(calc(var(--size) * 7), 20%);
  padding: 0 25px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cta-parent {
  width: 100%;
  height: 70px;
  background-color: var(--bg-color);
  z-index: 1;
  justify-content: center;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0 2px -1px #a7a6ba, 0 0 2px #a7a6ba66, 0 0 24px -4px #a7a6ba99;
}

.cta-button, .moreArtButton {
  text-align: center;
  cursor: pointer;
  min-height: 50px;
  max-width: 250px;
  box-sizing: border-box;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  font-family: SatoshiMedium, sans-serif;
  font-size: 15px;
  display: flex;
  position: relative;
}

.cta-button {
  color: var(--bg-color);
  background: var(--text-color);
  border: 2px solid var(--text-color);
  height: 50px;
  max-width: 100%;
  margin: 10px auto 20px;
}

.moreArtButton {
  color: var(--bg-color);
  background: var(--highlight-color);
  white-space: nowrap;
  max-width: 300px;
  position: fixed;
  bottom: 20px;
  left: 20px;
}

@media (hover: hover) and (pointer: fine) {
  .moreArtButton:not(:disabled):hover, .moreArtButton:not(:disabled):focus {
    color: var(--highlight-color);
    background: rgba(var(--bg-rgb), .9);
  }

  .cta-button:not(:disabled):hover, .cta-button:not(:disabled):focus {
    background: var(--highlight-color);
    border-color: var(--highlight-color);
    color: var(--bg-color);
  }
}

.svgIcon {
  fill: none;
  stroke: currentColor;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.error {
  width: 100%;
  height: 100%;
  background: rgb(var(--bg-rgb));
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.error > .box {
  padding: calc(var(--size) * 2.5);
  min-height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.error > .box > * {
  margin-top: calc(var(--size) * 2.5);
  max-width: calc(var(--size) * 50);
  width: 100%;
}

.error > .box > :first-of-type {
  margin: 0;
}

.error > .box > h2 {
  color: rgb(var(--error-rgb));
  font-family: SatoshiBold, sans-serif;
}

.error > .box > p {
  color: rgb(var(--text-rgb));
  font-family: SatoshiRegular, sans-serif;
  font-size: 20px;
  line-height: 1.45;
}

.mini-popup {
  width: 280px;
  color: var(--text-color);
  background-color: var(--bg-color);
  z-index: 2;
  border-radius: 30px;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0 2px -1px #a7a6ba, 0 0 2px #a7a6ba66, 0 0 24px -4px #a7a6ba99;
}

.open {
  display: flex;
}

.closed {
  display: none;
}

.mini-popup-selection {
  cursor: pointer;
  text-align: center;
  z-index: 2;
  border-radius: 30px;
  padding: 5px 10px;
  font-family: SatoshiMedium, sans-serif;
  font-size: 15px;
}

.mini-popup-selection.selected {
  background-color: var(--text-color);
  color: var(--bg-color);
}

@media (hover: hover) and (pointer: fine) {
  .mini-popup-selection:not(:disabled):hover, .mini-popup-selection:not(:disabled):focus {
    color: var(--highlight-color);
    border-color: var(--highlight-color);
  }

  .mini-popup-selection.selected:not(:disabled):hover, .mini-popup-selection.selected:not(:disabled):focus {
    color: var(--bg-color);
    background-color: var(--highlight-color);
  }
}

.languagePhantom {
  width: 110%;
  height: calc(100% + 250px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.intro {
  width: 100%;
  height: 100%;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.intro.hidden {
  pointer-events: none;
}

.intro:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  transition: opacity .6s;
  position: fixed;
  top: 0;
  left: 0;
}

.intro.hidden:before {
  opacity: 0;
  transition: opacity .6s .6s;
}

.intro.visible:before {
  opacity: 1;
}

.introBox {
  min-height: 100%;
  min-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
  transition: opacity .6s;
  display: flex;
}

.intro.hidden .introBox {
  opacity: 0;
}

.intro.visible .introBox {
  opacity: 1;
}

.introBackground {
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.introBackground > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.introFreeBanner, .introBoxContent {
  max-width: 320px;
  width: 100%;
  border-radius: 30px;
  position: relative;
  box-shadow: 0 0 2px -1px #a7a6ba, 0 0 2px #a7a6ba66, 0 0 24px -4px #a7a6ba99;
}

.introFreeBanner {
  color: #fff;
  letter-spacing: -.14px;
  text-align: left;
  background: #1f18c0;
  padding: 20px 30px;
  font-family: SatoshiMedium;
  font-size: 14px;
  line-height: 19px;
}

.introBoxContent {
  background: rgba(var(--bg-rgb), .9);
  padding: 10px 10px 20px;
  overflow: visible;
}

.introFreeBanner > button {
  background: var(--text-color);
  color: var(--bg-color);
  margin-top: 20px;
  margin-bottom: 0;
}

.introFreeBanner > button:hover {
  background: var(--bg-color);
  color: var(--text-color);
}

.introTopBar {
  width: calc(100% - 20px);
  height: 60px;
  border-bottom: 1px solid var(--divider-color);
  margin: 0 10px;
  position: relative;
}

.introDate {
  font-family: SatoshiRegular;
  font-size: 12px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.introActions {
  width: 100%;
  flex-direction: row;
  display: flex;
}

.introActions > * {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 10px 0;
  position: relative;
}

.introShareIcon {
  max-width: 30px;
  max-height: 30px;
  position: relative;
  top: 5px;
}

.introShareIcon > * {
  fill: none;
  stroke: var(--text-color);
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.introShareIcon:hover > * {
  fill: none;
  stroke: var(--highlight-color);
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.introLogo {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.introLogo img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.introTitle, .introHello {
  text-align: left;
  width: calc(100% - 50px);
  margin: 20px 0;
  font-size: 14px;
  position: relative;
  left: 20px;
}

.introTitle {
  font-family: SatoshiBold;
}

.introHello {
  font-family: SatoshiRegular;
}

.languageBar {
  width: calc(100% - 40px);
  height: 30px;
  margin: 10px 20px;
  position: relative;
}

.languageButton {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.languageLabel {
  font-family: SatoshiMedium;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.languageName {
  font-family: SatoshiMedium;
  font-size: 15px;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translate(0, -50%);
}

.languageButton > svg {
  height: 20px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.introStartButtonContainer {
  width: calc(100% - 20px);
  height: 70px;
  margin: 10px;
  position: relative;
}

.introStartButtonContainer > button {
  width: 100%;
  height: 50px;
  background: var(--text-color);
  color: var(--bg-color);
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  font-family: SatoshiMedium;
  font-size: 15px;
  transition: color .2s, border-color .2s, opacity .2s;
  display: flex;
  position: relative;
  top: 10px;
}

.introFooter {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.introFooter a {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--text-color);
  flex-shrink: 1;
  margin: 0 10px;
  font-family: SatoshiMedium;
  font-size: 12px;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

.introFooter a:hover {
  color: var(--highlight-color);
}

.loading-bar {
  height: 30px;
  width: 100%;
  border: 1px solid var(--text-color);
  background-color: var(--bg-color);
  transition: all .1s var(--quad);
  border-radius: 30px;
  margin-bottom: 15px;
  position: relative;
  top: 20px;
}

.loading-progress, .loading-progress-fake {
  height: 22px;
  width: 22px;
  min-width: 22px;
  max-width: calc(100% - 6px);
  background-color: var(--text-color);
  border-radius: 30px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.loading-progress {
  transition: all 3s linear;
}

.loading-progress-fake {
  animation: progress 10s linear forwards;
}

@keyframes progress {
  0% {
    width: 22px;
  }

  100% {
    width: 140px;
  }
}

.assetErrorBanner {
  text-align: left;
  width: calc(100% - 30px);
  color: #fff;
  letter-spacing: 1px;
  z-index: 1;
  background-color: #e83692;
  padding: 10px 35px 10px 10px;
  font-family: SatoshiMedium, sans-serif;
  font-size: 14px;
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translate(-50%);
}

.assetErrorBanner > svg {
  width: 30px;
  position: absolute;
  top: 5px;
  right: 5px;
}

/*# sourceMappingURL=index.5344c038.css.map */
