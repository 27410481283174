.intro {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  text-align: center;
}
.intro.hidden {
  pointer-events: none;
}

.intro::before {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  transition: opacity 0.6s ease;
}
.intro.hidden::before {
  opacity: 0;
  transition: opacity 0.6s ease 0.6s;
}
.intro.visible::before {
  opacity: 1;
}

.introBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  min-width: 100%;
  transition: opacity 0.6s ease;
  gap: 10px;
  padding: 20px 0;
}
.intro.hidden .introBox {
  opacity: 0;
}
.intro.visible .introBox {
  opacity: 1;
}

.introBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--bg-color);
  background-position: center center;
  background-size: cover;
}

.introBackground > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.introFreeBanner,
.introBoxContent {
  max-width: 320px;
  width: 100%;
  position: relative;
  border-radius: 30px;
  box-shadow: 0px 0px 2px -1px rgba(167, 166, 186, 1), 0px 0px 2px 0px rgba(167, 166, 186, 0.4),
    0px 0px 24px -4px rgba(167, 166, 186, 0.6);
}

.introFreeBanner {
  background: #1f18c0;
  color: white;
  font-family: 'SatoshiMedium';
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.14px;
  padding: 20px 30px;
  text-align: left;
}

.introBoxContent {
  background: rgba(var(--bg-rgb), 0.9);
  padding: 10px 10px 20px 10px;
  overflow: visible;
}

.introFreeBanner > button {
  background: var(--text-color);
  color: var(--bg-color);
  margin-top: 20px;
  margin-bottom: 0;
}

.introFreeBanner > button:hover {
  background: var(--bg-color);
  color: var(--text-color);
}

.introTopBar {
  position: relative;
  width: calc(100% - 20px);
  height: 60px;
  margin: 0px 10px;
  border-bottom: 1px solid var(--divider-color);
}

.introDate {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(0, -50%);
  font-size: 12px;
  font-family: 'SatoshiRegular';
}

.introActions {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.introActions > * {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 10px 0;
  cursor: pointer;
}

.introShareIcon {
  position: relative;
  top: 5px;
  max-width: 30px;
  max-height: 30px;
}

.introShareIcon > * {
  fill: none;
  stroke: var(--text-color);
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.introShareIcon:hover > * {
  fill: none;
  stroke: var(--highlight-color);
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.introLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
}
.introLogo img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.introTitle,
.introHello {
  position: relative;
  text-align: left;
  width: calc(100% - 50px);
  left: 20px;
  margin: 20px 0;
  font-size: 14px;
}

.introTitle {
  font-family: 'SatoshiBold';
}

.introHello {
  font-family: 'SatoshiRegular';
}

.languageBar {
  position: relative;
  width: calc(100% - 40px);
  height: 30px;
  margin: 10px 20px;
}

.languageButton {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.languageLabel {
  position: absolute;
  top: calc(50%);
  left: 0;
  transform: translate(0, -50%);
  font-family: 'SatoshiMedium';
  font-size: 15px;
}

.languageName {
  position: absolute;
  top: calc(50%);
  transform: translate(0, -50%);
  right: 25px;
  font-family: 'SatoshiMedium';
  font-size: 15px;
}

.languageButton > svg {
  position: absolute;
  top: calc(50%);
  transform: translate(0, -50%);
  right: 0;
  height: 20px;
}

.introStartButtonContainer {
  position: relative;
  width: calc(100% - 20px);
  height: 70px;
  margin: 10px 10px;
}

.introStartButtonContainer > button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 100%;
  height: 50px;
  top: 10px;
  background: var(--text-color);
  color: var(--bg-color);
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  transition: color 0.2s ease, border-color 0.2s ease, opacity 0.2s ease;
  font-family: 'SatoshiMedium';
}

.introFooter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}
.introFooter a {
  margin: 0 10px;
  white-space: nowrap;
  display: block;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  font-family: 'SatoshiMedium';
  font-size: 12px;
  color: var(--text-color);
}

.introFooter a:hover {
  color: var(--highlight-color);
}
