.mini-popup {
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 280px;
  border-radius: 30px;
  color: var(--text-color);
  background-color: var(--bg-color);
  padding: 20px;
  box-shadow: 0px 0px 2px -1px rgba(167, 166, 186, 1), 0px 0px 2px 0px rgba(167, 166, 186, 0.4),
    0px 0px 24px -4px rgba(167, 166, 186, 0.6);
  z-index: 2;
}

.open {
  display: flex;
}

.closed {
  display: none;
}

.mini-popup-selection {
  border-radius: 30px;
  padding: 5px 10px;
  cursor: pointer;
  text-align: center;
  font-family: 'SatoshiMedium', sans-serif;
  font-size: 15px;
  z-index: 2;
}

.mini-popup-selection.selected {
  background-color: var(--text-color);
  color: var(--bg-color);
}

@media (hover: hover) and (pointer: fine) {
  .mini-popup-selection:not(:disabled):hover,
  .mini-popup-selection:not(:disabled):focus {
    color: var(--highlight-color);
    border-color: var(--highlight-color);
  }

  .mini-popup-selection.selected:not(:disabled):hover,
  .mini-popup-selection.selected:not(:disabled):focus {
    color: var(--bg-color);
    background-color: var(--highlight-color);
  }
}

.languagePhantom {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 110%;
  height: calc(100% + 250px);
}
