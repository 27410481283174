.loading-bar {
  position: relative;
  top: 20px;
  height: 30px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid var(--text-color);
  background-color: var(--bg-color);
  margin-bottom: 15px;
  transition: all 0.1s var(--quad);
}

.loading-progress,
.loading-progress-fake {
  position: absolute;
  top: 3px;
  left: 3px;
  height: 22px;
  width: 22px;
  min-width: 22px;
  max-width: calc(100% - 6px);
  background-color: var(--text-color);
  border-radius: 30px;
}

.loading-progress {
  transition: all 3s linear;
}

.loading-progress-fake {
  animation: progress 10s linear 0s 1 normal forwards;
}

@keyframes progress {
  0% {
    width: 22px;
  }
  100% {
    width: 140px;
  }
}
