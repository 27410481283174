.assetErrorBanner {
  text-align: left;
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translate(-50%);
  width: calc(100% - 30px);
  padding: 10px 35px 10px 10px;
  background-color: #e83692;
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
  font-family: 'SatoshiMedium', sans-serif;
  z-index: 1;
}

.assetErrorBanner > svg {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
}
