.error {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: rgb(var(--bg-rgb));
}
.error > .box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: calc(var(--size) * 2.5);
  min-height: 100%;
  text-align: center;
}
.error > .box > * {
  margin-top: calc(var(--size) * 2.5);
  max-width: calc(var(--size) * 50);
  width: 100%;
}
.error > .box > :first-of-type {
  margin: 0;
}
.error > .box > h2 {
  font-family: 'SatoshiBold', sans-serif;
  color: rgb(var(--error-rgb));
}
.error > .box > p {
  font-family: 'SatoshiRegular', sans-serif;
  font-size: 20px;
  line-height: 1.45;
  color: rgb(var(--text-rgb));
}
